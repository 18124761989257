<script>

import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import Preloader from "@/components/widgets/preloader";
import MessageMenu from './MessageMenu'
import MessageAttachments from './MessageAttachments'
import MessageReplies from './MessageReplies'
import Repository from "@/app/repository/repository-factory";
import MessageRecipientList from "@/router/views/message/MessageRecipientList";
import EventBus from "@/event-bus";

const MessageRepository = Repository.get("MessageRepository");
const NotificationRepository = Repository.get("NotificationRepository");

/**
 * Email-read component
 */
export default {
  components: {
    MessageRecipientList,
    Layout,
    PageHeader,
    Preloader,
    MessageMenu,
    MessageAttachments,
    MessageReplies,
  },
  data() {
    return {
      title: 'Wiadomości',
      items: [
        {
          text: 'Wiadomości',
          href: '/',
        },
        {
          text: 'Szczegóły',
          active: true,
        },
      ],
      showModal: false,
      preloader: true,
      messageData: {},
      notificationStats: null,
      countGuardiansReadMessage: 0,
    }
  },
  created() {
    this.getMessageDetails();
    this.getNotificationStats();
    this.getCountGuardiansReadMessage();
    EventBus.$on('MessageReplyAdded', () => {
      this.getMessageDetails();
    });
  },
  methods: {
    getMessageDetails() {
      if (this.$route.params.id) {
        MessageRepository.getItem(this.$route.params.id).then((response) => {
          this.messageData = response.data;
          console.log(this.messageData);
          this.preloader = false;
        }).catch(error => {
          this.preloader = false;
          console.log(error);
        });
        MessageRepository.markAsRead(this.$route.params.id).then(() => {
            this.preloader = false;
        }).catch(error => {
            this.preloader = false;
            console.log(error);
        });
      }
    },
    getNotificationStats() {
      NotificationRepository.getStats(this.$route.params.id).then((response) => {
        this.notificationStats = response.data.total;
      }).catch(error => {
        console.log(error);
      });
    },
    getCountGuardiansReadMessage() {
      MessageRepository.getCountGuardiansReadMessage(this.$route.params.id).then((response) => {
        this.countGuardiansReadMessage = response.data.count;
      }).catch(error => {
        console.log(error);
      });
    },
    isDraftMessage(){
      return this.messageData.status === 'draft';
    },
    markAsUnread(){
      MessageRepository.markAsUnread(this.$route.params.id).then(() => {
        this.preloader = false;
          MessageRepository.countUnread().then(response => {
              EventBus.$emit('UnreadMessageCountChanged', response.data.count);
          }).catch(error => {
              console.log(error);
          });
      }).catch(error => {
        this.preloader = false;
        console.log(error);
      });

    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <preloader v-if="preloader"></preloader>

    <template v-if="!preloader">
      <div class="row">
      <div class="col-12">
        <!-- Left sidebar -->
        <div class="email-leftbar card">
          <MessageMenu/>
        </div>
        <!-- End Left sidebar -->

        <!-- Right Sidebar -->
        <div class="email-rightbar mb-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <message-recipient-list :message-data="messageData" presentation-mode="preview"/>
                </div>
                <div class="col-3">
                  <div class="btn-toolbar px-3 justify-content-end" role="toolbar" v-if="!isDraftMessage()">
                    <div class="btn-group mb-2 mb-sm-0">
                      <b-button variant="primary" size="sm" @click="markAsUnread" title="Oznacz jako nieprzeczytaną">
                        <span class="small text-nowrap"><i class="mdi mdi-email-mark-as-unread"></i> Oznacz jako nieprzeczytaną</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mb-2 mt-2"/>
              <span class="mt-0 mb-0 font-size-24">{{messageData.title}}</span>
              <hr class="mb-0 mt-2" />
              <div class="row">
                <div class="col-12">
                  <span class="small text-muted ml-1 mr-2 right">
                    Data ostatniej aktualizacji: <strong>{{messageData.changedAt.substring(0,16)}}</strong>
                  </span>
                  <span class="small text-muted mr-2 right">
                    |&nbsp;&nbsp;&nbsp;Data wysyłki: <strong>{{messageData.sendAt.substring(0,16)}}</strong>
                  </span>
                  <span v-if="messageData.author.isFoundationEmployee === true" class="small text-muted mr-2 right">
                    |&nbsp;&nbsp;&nbsp;Odczytanie wiadomości: <strong>{{ countGuardiansReadMessage }} z {{ messageData.recipients.length }}</strong>
                  </span>
                  <span v-if="messageData.author.isFoundationEmployee === true" class="small text-muted mr-2 right">
                    |&nbsp;&nbsp;&nbsp;Powiadomienia e-mail: <strong>{{ notificationStats.send }} z {{ notificationStats.total}}</strong>
                  </span>
                </div>
              </div>
              <hr class="mt-0" />
              <div v-html="messageData.content"> </div>

              <MessageAttachments :message="messageData" />

              <MessageReplies :message="messageData" />

            </div>
          </div>
        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>
    </template>
  </Layout>
</template>
