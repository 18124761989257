<script>
import simplebar from "simplebar-vue";
import EventBus from "../../../event-bus";
import Repository from "@/app/repository/repository-factory";
import Vue from "vue";
import {required} from "vuelidate/lib/validators";

const MessageRepository = Repository.get("MessageRepository");

export default {
  components: {simplebar},
  props: {
    message: {
      type: Object,
      default: null,
    },
    presentationMode: {
      type: String,
      default: 'preview',
      required: false,
    },
  },
  data: function () {
    return {
      submitted: false,
      form: {
        content: "",
        attachment: null,
      },
    }
  },
  validations: {
    form: {
      content: {
        required,
      },
    },
  },
  methods: {
    isDraftMessage() {
      return this.message.status === 'draft';
    },
    showReplies() {
      return this.isDraftMessage() === false && this.message.subaccountContextName !== null;
    },
    isEditMode() {
      return this.presentationMode === 'edit';
    },
    sendReply() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let formData = new FormData();
        formData.append('attachment', this.form.attachment);
        formData.append('content', this.form.content);

        if (this.form.attachment !== null) {
          const size = this.form.attachment.size;
          if (size > 5048576) {
            Vue.swal({
              icon: "error",
              title: "Załącznik jest za duży",
              text: "Załącznik nie może być większy niż 5MB.",
            });
            return;
          }
        }

        MessageRepository.sendReply(this.message.uuid, formData).then(() => {
          EventBus.$emit('MessageReplyAdded', this.message.uuid);
          Vue.swal({
            icon: "success",
            toast: false,
            position: 'top',
            title: "SUKCES! ",
            text: 'Odpowiedź została dodana do wiadomości!',
            showConfirmButton: false,
            timer: 1500,
          });
          this.form = {};
        }).catch(error => {
          Vue.swal({
            icon: "error",
            position: 'top',
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true
          });
        })
        this.handleScroll();
      }
      this.submitted = false;
    },
    handleFileUpload(){
      this.form.attachment = this.$refs.file.files[0];
    },
    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop =
              this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },
    handleChooseFile: function() {
      document.getElementById("replyFileUpload").click()
    },
    downloadReplyAttachment(attachmentId, attachmentName, replyId) {
      const promise = MessageRepository.getReplyAttachment(this.message.uuid, attachmentId, replyId);
      return promise.then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachmentName);
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error);
      })
    },
  },
  mounted() {
    var container2 = document.querySelector(
        "#containerElement .simplebar-content-wrapper"
    );
    container2.scrollTo({ top: 990000, behavior: "smooth" });
  },
}
</script>
<template>
  <div class="row">
    <div class="w-100 user-chat" v-if="showReplies()">
      <div class="card" style="display:block;">
        <div class="chat-users">
          <hr  class="mx-3" />
          <h4 class="p-3">Odpowiedzi <span class="badge badge-primary">{{ message.replies.length}}</span></h4>
          <div class="chat-conversation p-3" style="min-height:auto;">
            <simplebar
                id="containerElement"
                ref="current"
            >
              <ul class="list-unstyled pt-3">
                <li
                    v-for="reply of message.replies"
                    :key="reply.uuid"
                    :class="[{ right: `${reply.author.isFoundationEmployee}` === 'true' }]"
                >
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">{{ reply.author.name }}</div>
                      <div class="pre-formatted" v-html="reply.content"></div>
                      <hr class="mt-1 mb-1" />
                      <div class="conversation-files" v-if="reply.attachments.length > 0">
                        <span
                            v-for="attachment of reply.attachments"
                            :key="attachment.attachmentId"
                            class="attachment"
                        >
                          <a href="javascript:void(0)" class="font-weight-medium" @click="downloadReplyAttachment(attachment.attachmentId, attachment.originalFilename, reply.uuid)">
                           <i class="mdi mdi-download small"></i><span class="small"> {{attachment.originalFilename}}</span>
                          </a>
                        </span>
                      </div>
                      <p class="chat-time mb-0 mt-0">
                        <i class="bx bx-time-five align-middle me-1 small"></i> <span class="small">{{ reply.sendAt }}</span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </simplebar>
          </div>
          <div class="p-3 chat-input-section">
            <form @submit.prevent="sendReply" class="row">
              <div class="col">
                <div class="position-relative">
                  <div class="mb-1">
                    <a
                      href="javascript: void(0);"
                      v-b-tooltip.hover
                      title="Dodaj załącznik"
                      @click="handleChooseFile"
                      id="replyAttachmentIcon"
                    >
                      <i class="mdi mdi-file-document-outline"></i>
                      <input type="file" id="replyFileUpload" ref="file" v-on:change="handleFileUpload()" hidden/>
                      <span class="small"> {{ form.attachment ? form.attachment.name : 'Dodaj załącznik' }}</span>
                    </a>
                    <br>
                    <span class="small">Maksymalna wielkość załącznika to <strong>5MB</strong>, a dopuszczalne rozszerzenia to: <strong>pdf, doc, docx, xls, xlsx, jpg, png.</strong></span>
                  </div>
                  <textarea
                      type="text"
                      rows="5"
                      maxlength="5000"
                      v-model="form.content"
                      class="form-control chat-input rounded"
                      placeholder="Treść odpowiedzi..."
                      :class="{
                        'is-invalid': submitted && $v.form.content.$error,
                      }"
                  />
                  <div
                      v-if="submitted && $v.form.content.$error"
                      class="invalid-feedback"
                  >
                      <span v-if="!$v.form.content.required"
                      >Treść odpowiedzi jest wymagana.</span
                      >
                  </div>
                </div>
              </div>

              <div class="col-auto mt-5">
                <button
                    type="submit"
                    class="btn btn-primary btn-rounded chat-send w-md"
                >
                  <span class="d-none d-sm-inline-block mr-2">Wyślij </span>
                  <i class="mdi mdi-send"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.pre-formatted {
  white-space: pre-wrap;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
}
</style>
